import CONDITIONS_STRINGS from './conditions';
import P90_STRINGS from './p90';
import DESIGN_STRINGS from './design';
import FINANCIAL_STRINGS from './financials';
import PROJECT_STRINGS from './project';
import SIMULATION_STRINGS from './simulation';
import CONSTANT_STRINGS from './constants';
import ERROR_STRINGS from './errors';
import GENERAL_STRINGS from './general';
import REPORT_STRINGS from './report';
import WIDGET_STRINGS from './widgets';
import IMAGE_STRINGS from './image';

const Translations = {
    conditions: CONDITIONS_STRINGS,
    p90: P90_STRINGS,
    design: DESIGN_STRINGS,
    financial: FINANCIAL_STRINGS,
    project: PROJECT_STRINGS,
    simulation: SIMULATION_STRINGS,
    constants: CONSTANT_STRINGS,
    errors: ERROR_STRINGS,
    general: GENERAL_STRINGS,
    report: REPORT_STRINGS,
    widgets: WIDGET_STRINGS,
    image: IMAGE_STRINGS,
};

export default Translations;
