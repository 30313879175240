/**
 * Predeclared messages file for react-intl
 *
 * The `defineMessages` call is so that all messages can be parsed out via AST for translation.
 */

import { defineMessages } from 'react-intl';

const ERROR_STRINGS = defineMessages({
    no_design_available: {
        id: 'error.no_design_available',
        description: `Error message for No design available (used in FieldSegmentsTable, ShadingByFieldSegmentTable,
            DesignWiringZoneTable, SystemMetricsTable)`,
        defaultMessage: 'No design available.',
    },
    design_data_unavailable: {
        id: 'error.design_data_unavailable',
        description: 'Error message for Design data unavailable (used in FieldSegmentsTable, DesignWiringZoneTable)',
        defaultMessage: 'Design data unavailable.',
    },
    incomplete_design: {
        id: 'error.incomplete_design',
        description: `Error message for Incomplete design (used in FieldSegmentsTable, ShadingByFieldSegmentTable,
            DesignWiringZoneTable)`,
        defaultMessage: 'Incomplete design. No field segments.',
    },
    no_sim_available: {
        id: 'error.no_sim_available',
        description: 'Error message for No simulation available (used in ShadingByFieldSegmentTable)',
        defaultMessage: 'No simulation available.',
    },
    sim_in_progress: {
        id: 'error.sim_in_progress',
        description:
            'Error message for when a widget that depends on simulation results cannot load because the simulation is incomplete.',
        defaultMessage:
            'Simulation is still in progress. Wait until the simulation completes for this project and reload the report.',
    },
    no_fin_results_available: {
        id: 'error.no_fin_results_available',
        description: 'Error message for when no financial results have been calculated.',
        defaultMessage: 'There are no financial results available for this project.',
    },
    module_data_not_available: {
        id: 'error.module_data_not_available',
        description: 'Error message for simulation module data not available (used in ShadingByFieldSegmentTable)',
        defaultMessage: 'Per-module simulation data unavailable.',
    },
    data_not_available: {
        id: 'error.data_not_available',
        description: 'Error message for Data not available (used in CashFlowTable)',
        defaultMessage: 'Data not available.',
    },
    design_not_found: {
        id: 'error.design_not_found',
        description: 'Design not found',
        defaultMessage: 'Design not found.',
    },
    no_condition_set: {
        id: 'error.no_condition_set',
        description: 'Error message for no condition set (used in ConditionSetTable)',
        defaultMessage: 'No condition set.',
    },
    project_couldnt_load: {
        id: 'error.project_couldnt_load',
        description: 'Error message for project unable to load',
        defaultMessage: 'The specified project could not load.',
    },
    no_probability_distribution_config: {
        id: 'error.no_probability_distribution_config',
        description: 'Error message for missing probability distribution config',
        defaultMessage: 'Enable P90 in Condition Sets',
    },
});

export default ERROR_STRINGS;
