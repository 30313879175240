/**
 * Predeclared messages file for react-intl
 *
 * The `defineMessages` call is so that all messages can be parsed out via AST for translation.
 */

import { defineMessages } from 'react-intl';

const WIDGET_STRINGS = defineMessages({
    cash_flow_table_header: {
        id: 'widget.cash_flow_table.header',
        description: 'Cash Flow Table widget header (used in CashFlowTable)',
        defaultMessage: 'Cash Flow Table',
    },
    condition_set_table_header: {
        id: 'widget.condition_set_table.header',
        description: 'Condition Set Table widget header',
        defaultMessage: 'Condition Set',
    },
    custom_image_header: {
        id: 'widget.custom_image.header',
        description: 'Custom Image widget header',
        defaultMessage: 'Image',
    },
    financial_summary_table_header: {
        id: 'widget.financial_summary_table.header',
        description: 'Financial Summary Table widget header',
        defaultMessage: 'Financial Banner',
    },
    monthly_shading_table_header: {
        id: 'widget.monthly_shading_table.header',
        description: 'Monthly Shading Table widget header',
        defaultMessage: 'Monthly Shading',
    },
    solar_access_by_month_table_header: {
        id: 'widget.solar_access_by_month_table.header',
        description: 'Solar Access by Month Table widget header',
        defaultMessage: 'Solar Access by Month',
    },
    project_detail_header: {
        id: 'widget.project_detail.header',
        description: 'Project Detail widget header',
        defaultMessage: 'Project Details',
    },
    project_location_header: {
        id: 'widget.project_location.header',
        description: 'Project Location widget header',
        defaultMessage: 'Project Location',
    },
    scenario_summary_header: {
        id: 'widget.scenario_summary.header',
        description: 'Scenario Summary widget header',
        defaultMessage: 'Scenario Summary',
    },
    p90_parameters_table_header: {
        id: 'widget.p90_parameters_table.header',
        description: 'Condition Set (Production Probability) widget header',
        defaultMessage: 'Condition Set (Production Probability)',
    },
    p90_chart_header: {
        id: 'widget.p90_chart.header',
        description: 'Production Probability widget header',
        defaultMessage: 'Production Probability',
    },
    design_render_header: {
        id: 'widget.design_render.header',
        description: 'Design Render widget header',
        defaultMessage: 'Design Render',
    },
    design_bom_table_header: {
        id: 'widget.design_bom_table.header',
        description: 'Design BOM Table widget header',
        defaultMessage: 'Design BOM',
    },
    design_wiring_zone_header: {
        id: 'widget.design_wiring_zone.header',
        description: 'Design Wiring Zone widget header',
        defaultMessage: 'Design Wiring Zone',
    },
    system_loss_chart_header: {
        id: 'widget.system_loss_chart.header',
        description: 'System Loss Chart widget header',
        defaultMessage: 'System Loss',
    },
    shading_by_field_segment_header: {
        id: 'widget.shading_by_field_segment.header',
        description: 'Shading By Field Segment widget header',
        defaultMessage: 'Shading by Field Segment',
    },
    system_metrics_table_header: {
        id: 'widget.system_metrics_table.header',
        description: 'System Metrics Table widget header',
        defaultMessage: 'System Metrics',
    },
    logo_header: {
        id: 'widget.logo.header',
        description: 'Logo widget header',
        defaultMessage: 'Logo',
    },
    consumption_energy_chart_header: {
        id: 'widget.consumption_energy_chart.header',
        description: 'Consumption Energy Chart widget header',
        defaultMessage: 'Energy Consumption',
    },
    monthly_savings_chart_header: {
        id: 'widget.monthly_savings_chart.header',
        description: 'Monthly Savings Chart widget header',
        defaultMessage: 'Monthly Savings',
    },
    financial_metrics_header: {
        id: 'widget.financial_metrics.header',
        description: 'Financial Metrics widget header',
        defaultMessage: 'Financial Metrics',
    },
    lifetime_production_header: {
        id: 'widget.lifetime_production.header',
        description: 'Lifetime Production widget header',
        defaultMessage: 'Lifetime Production',
    },
    utility_bill_pie_chart_header: {
        id: 'widget.utility_bill_pie_chart.header',
        description: 'Utility Bill Pie Chart widget header',
        defaultMessage: 'Utility Breakdown',
    },
    line_header: {
        id: 'widget.line.header',
        description: 'Line widget header',
        defaultMessage: 'Line',
    },
    rectangle_header: {
        id: 'widget.rectangle.header',
        description: 'Rectangle widget header',
        defaultMessage: 'Rectangle',
    },
    generic_text_header: {
        id: 'widget.generic_text.header',
        description: 'Generic Text widget header',
        defaultMessage: 'Text',
    },
    custom_text_header: {
        id: 'widget.custom_text.header',
        description: 'Custom Text widget header',
        defaultMessage: 'Text',
    },
    rich_text_header: {
        id: 'widget.rich_text.header',
        description: 'Rich Text widget header',
        defaultMessage: 'Rich Text',
    },
    design_snapshot_header: {
        id: 'widget.design_snapshot.header',
        description: 'Design Snapshot widget header',
        defaultMessage: 'Design Snapshot',
    },
    not_found_header: {
        id: 'widget.not_found.header',
        description: 'Not Found widget header',
        defaultMessage: 'Widget Not Found',
    },
    fs_table_footer_1: {
        id: 'widget.fs_table_footer_1',
        description: 'Field Segment Table Widget footer 1',
        defaultMessage: 'approximate, varies based on inverter performance',
    },
    fs_table_footer_2: {
        id: 'widget.fs_table_footer_2',
        description: 'Field Segment Table Widget footer 2',
        defaultMessage: `based on location Optimal POA Irradiance of
            {poa_irradiance} kWh/m<sup>2</sup> at {tilt}\u00b0 tilt and {azimuth}\u00b0 azimuth`,
    },
    category_financial: {
        id: 'widget.category.financial',
        description: 'Header for the Financial Components widget category',
        defaultMessage: 'Financial Components',
    },
    category_generic: {
        id: 'widget.category.generic',
        description: 'Header for the Text and Drawing Components widget category',
        defaultMessage: 'Text and Drawing Components',
    },
    category_ignore: {
        id: 'widget.category.ignore',
        description: 'Header for the Deprecated Components widget category',
        defaultMessage: 'Deprecated Components',
    },
    category_project: {
        id: 'widget.category.project',
        description: 'Header for the Project and Design Components widget category',
        defaultMessage: 'Project and Design Components',
    },
    not_found: {
        id: 'widget.not_found.body',
        description: 'Body text for the Not Found widget (used in NotFound)',
        defaultMessage: 'Widget: {name} not found',
    },
    add_text: {
        id: 'widget.add_text',
        description: 'Prompt to add text (used in custom_text as placeholder)',
        defaultMessage: 'Add text',
    },
    type_something_here: {
        id: 'widget.type_something_here',
        description: 'Prompt to type something (used in generic_text as placeholder)',
        defaultMessage: 'Type something here...',
    },
    enter_text_here: {
        id: 'widget.enter_text_here',
        description: 'Prompt to enter text (used in RichText as placeholder)',
        defaultMessage: 'Enter text here...',
    },
    format_rich_text: {
        id: 'widget.rich_text.format',
        description: 'Format Rich Text',
        defaultMessage: 'Format Rich Text',
    },
    project_settings: {
        id: 'widget.project_settings',
        description: 'Header for Project Settings section of widget configuration',
        defaultMessage: 'Project Settings',
    },
    enable_configuration_label: {
        id: 'widget.enable_configuration_label',
        description: 'Label for the button that toggles whether or not this widget is configurable for each project',
        defaultMessage: 'Enable Configuration',
    },
    enable_configuration_helper_text: {
        id: 'widget.enable_configuration_helper_text',
        description: 'Helper text explaining what the "Enable Configuration" toggle does.',
        defaultMessage: 'Team members will be able to add project-specific text for this widget',
    },
    shape_fill: {
        id: 'widget.shape.fill',
        description: 'Fill color of shape widget',
        defaultMessage: 'Fill',
    },
    shape_border: {
        id: 'widget.shape.border',
        description: 'Border color of shape widget',
        defaultMessage: 'Border',
    },
    shape_color: {
        id: 'widget.shape.color',
        description: 'Color of shape widget',
        defaultMessage: 'Color',
    },
    shape_border_width: {
        id: 'widget.shape.border_width',
        description: 'Border width of shape widget',
        defaultMessage: 'Border width',
    },
    shape_orientation: {
        id: 'widget.shape.orientation',
        description: 'Orientation of shape widget',
        defaultMessage: 'Orientation',
    },
    shape_rotate90: {
        id: 'widget.shape.rotate90',
        description: 'Rotate shape widget 90 degrees',
        defaultMessage: 'Rotate 90 degrees',
    },
    uploadable_image_file_rejected: {
        id: 'widget.uploadable_image.file_rejected',
        description: 'File rejected message (used in UploadableImage)',
        defaultMessage: 'File rejected on drop: {name} ({size})',
    },
    uploadable_image_error_uploading: {
        id: 'widget.uploadable_image.error_uploading_image',
        description: 'Uploading error message (used in UploadableImage)',
        defaultMessage: 'Error uploading image: {response_body}',
    },
    uploadable_image_invalid_file_type: {
        id: 'widget.uploadable_image.invalid_file_type',
        description: 'Invalid file type error message (used in UploadableImage)',
        defaultMessage: 'Invalid file type,{linebreak}try uploading a JPEG or PNG file.',
    },
    uploadable_image_drop_file_here: {
        id: 'widget.uploadable_image.drop_file_here',
        description: 'Drop file here (used in UploadableImage)',
        defaultMessage: 'Drop file here to upload',
    },
    uploadable_image_drop_image_here: {
        id: 'widget.uploadable_image.drop_image_here',
        description: 'Drop image here (used in UploadableImage)',
        defaultMessage: 'Drop image here',
    },
    configure: {
        id: 'widget.configure',
        description: 'Header for configuration section in widget panel (used in SidebarFormatForm)',
        defaultMessage: 'Configure',
    },
});

export default WIDGET_STRINGS;
