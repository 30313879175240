import { normalizeHsPower } from 'helioscope/app/utilities/helpers';
import Highcharts from 'highcharts';
import { ProbabilityDistributionConfig } from 'reports/modules/project/components/condition_sets/ConditionSetP90Edit';

const P90_CURVE_INTERVALS = 3;
const P90_CURVE_INTERVAL_POINTS = 20;

export function normalDensity(x: number, mean: number, standardDeviation: number) {
    // guassian function aka normal probability density function
    // https://en.wikipedia.org/wiki/Gaussian_function
    const exponent = -Math.pow(x - mean, 2) / (2 * Math.pow(standardDeviation, 2));
    return Math.exp(exponent) / (standardDeviation * Math.sqrt(2 * Math.PI));
}

export function generatePointsOfNormalDistributionCurve(
    intervals: number,
    pointsInInterval: number,
    mean: number,
    standardDeviation: number,
): [number, number][] {
    const stop = intervals * pointsInInterval * 2 + 1;
    const increment = standardDeviation / pointsInInterval;
    const data: [number, number][] = [];

    let x = mean - intervals * standardDeviation;
    let point: [number, number];

    for (let i = 0; i < stop; i++) {
        point = [x, normalDensity(x, mean, standardDeviation)];
        data.push(point);

        x += increment;
    }

    return data;
}

type ProbabilityValueParams = {
    pValue: string;
    label: string;
    color: string;
    zValue?: number;
};

// Z-values pulled from standard normal distribution table
// https://en.wikipedia.org/wiki/Standard_normal_table
const PROBABILITY_VALUE_PARAMS: ProbabilityValueParams[] = [
    {
        pValue: 'p99',
        label: 'P99',
        color: 'green',
        zValue: 2.35,
    },
    {
        pValue: 'p95',
        label: 'P95',
        color: 'blue',
        zValue: 1.64,
    },
    {
        pValue: 'p90',
        label: 'P90',
        color: 'orange',
        zValue: 1.28,
    },
    {
        pValue: 'p50',
        label: 'P50',
        color: 'red',
    },
];

const generatePValueSeriesOption = (
    pValueParams: ProbabilityValueParams,
    normalizedPower: number,
    totalVariability: number,
    stdDeviation: number,
    decimalPlaces: number,
    wattHourLabel: string,
): Highcharts.SeriesOptions => {
    const { zValue, color, label } = pValueParams;

    const pValueX = zValue ? normalizedPower * (1 - (totalVariability / 100) * zValue) : normalizedPower;
    const pValueY = normalDensity(pValueX, normalizedPower, stdDeviation);
    return {
        name: `${label}  <span style="color:#5F6B7C; opacity: 0.5; font-weight: 400">${pValueX.toFixed(
            decimalPlaces,
        )} ${wattHourLabel}</span>`,
        type: 'scatter',
        data: [[pValueX, pValueY]],
        color,
        marker: {
            symbol: 'circle',
            radius: 6,
        },
        tooltip: {
            headerFormat: '',
            pointFormat: `<b>${label}</b><br>Production: {point.x:.2f} ${wattHourLabel}`,
        },
    };
}

export const generateP90ChartConfig = (
    gridPower: number,
    probDistConfig: ProbabilityDistributionConfig,
): Highcharts.Options => {
    const { total_variability: totalVariability, probability_values: probabilityValues } = probDistConfig;
    const { normalizedPower, unitsLabel, decimalPlaces } = normalizeHsPower(gridPower);
    const stdDeviation = (totalVariability / 100) * normalizedPower;

    const wattHourLabel = `${unitsLabel}h`;

    const highlightedPoints: Highcharts.SeriesOptions[] = [];

    PROBABILITY_VALUE_PARAMS.forEach((pValueParams) => {
        const { pValue } = pValueParams;
        if (probabilityValues[pValue] || pValue === 'p50') {
            highlightedPoints.push(
                generatePValueSeriesOption(
                    pValueParams,
                    normalizedPower,
                    totalVariability,
                    stdDeviation,
                    decimalPlaces,
                    wattHourLabel,
                ),
            );
        }
    });

    const chartConfig: Highcharts.Options = {
        chart: {
            style: {
                pointerEvents: 'auto'
            }
        },
        xAxis: [
            {
                title: { text: `System Production ${wattHourLabel}` },
                alignTicks: false,
            },
        ],
        yAxis: [
            {
                title: { text: 'Probability' },
            },
        ],
        title: {
            text: null,
        },
        legend: {
            itemDistance: 8,
            itemMarginBottom: 8,
            itemMarginTop: 8,
        },
        credits: {
          enabled: false
        },
        series: [
            {
                name: 'Normal Distribution',
                type: 'area',
                data: generatePointsOfNormalDistributionCurve(
                    P90_CURVE_INTERVALS,
                    P90_CURVE_INTERVAL_POINTS,
                    normalizedPower,
                    stdDeviation),
                showInLegend: false,
                marker: {
                    radius: 0,
                },
                // disables the tooltip for the curve
                enableMouseTracking: false,
            },
            ...highlightedPoints,
        ],
    };

    return chartConfig;
};
