/**
 * Predeclared messages file for react-intl
 *
 * The `defineMessages` call is so that all messages can be parsed out via AST for translation.
 */

import { defineMessages } from 'react-intl';

const P90_STRINGS = defineMessages({
    weather_variability_header: {
        id: 'p90.weather_variability_header',
        description: 'Weather Variability Header',
        defaultMessage: 'Weather Variability',
    },
    system_variability_header: {
        id: 'p90.system_variability_header',
        description: 'System Variability Header',
        defaultMessage: 'System Variability',
    },
    total_variability_header: {
        id: 'p90.total_variability_header',
        description: 'Total Variability Header',
        defaultMessage: 'Resulting Weather & System Variability',
    },
    pv_module_modeling_parameters: {
        id: 'p90.system_variability.pv_module_modeling_parameters',
        description: 'PV module modeling parameters',
        defaultMessage: 'PV Module Modeling Parameters',
    },
    inverter_efficiency: {
        id: 'p90.system_variability.inverter_efficiency',
        description: 'Inverter efficiency',
        defaultMessage: 'Inverter Efficiency',
    },
    soiling_mismatch: {
        id: 'p90.system_variability.soiling_mismatch',
        description: 'Soiling mismatch',
        defaultMessage: 'Soiling Mismatch',
    },
    degradation_estimation: {
        id: 'p90.system_variability.degradation_estimation',
        description: 'Degradation estimation',
        defaultMessage: 'Degradation Estimation',
    },
    custom_variability: {
        id: 'p90.system_variability.custom_variability',
        description: 'Custom variability',
        defaultMessage: 'Custom Variability',
    },
    weather_variability_datasource: {
        id: 'p90.weather_variability.datasource',
        description: 'Weather variability datasource',
        defaultMessage: 'Datasource',
    },
    annual_weather_variability: {
        id: 'p90.weather_variability.annual_variability',
        description: 'Annual weather variability',
        defaultMessage: 'Annual Variability',
    },
});
export default P90_STRINGS;
